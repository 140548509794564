
//Order

document.InputId;

$('.orderBody .field input')
    .focus(function () {
        document.inputId = $(this).attr('id');
        $('label[data-input="' + document.inputId + '"]').removeClass('inactive');
    })
    .focusout(function () {

        document.inputId = $(this).attr('id');

        if ($(this).val().length <= 0) {

            $('label[data-input="' + document.inputId + '"]').addClass('inactive');

            $(this).removeClass("is-success");
            $(this).removeClass("is-danger");

            $('p[data-message="' + document.inputId + '"]').html("");

        } else {

            validateInput($(this));

        }

    });




function ValidatorClass() {

    var returnFacade = [false, "error"];

    this.szallitas = function () {

        var validatorHasError = !(validateForm());

        console.log(validatorHasError);

        returnFacade[0] = validatorHasError;
        returnFacade[1] = "hiba";

        return returnFacade;
    }

    this.osszesito = function () {
        returnFacade = [true, ""]
        return returnFacade;
    }

    this.fizetes = function () {
        returnFacade = [false, "error"]
        return returnFacade;
    }

    this.get = function (processName) {

        if (processName == "szallitas") {

            return Validator.szallitas();

        } else if (processName == "osszesito") {

            return Validator.osszesito();

        } else if (processName == "fizetes") {

            return Validator.fizetes();

        } else {

            return false;

        }
    }
}



function Process(arg) {
    this.name = arg.name;
    this.active = false;
    this.ready = false;

    this.validation = arg.validation;

    this.setReady = function () {
        this.active = true;
        this.ready = true;
    }

    this.setActive = function () {
        this.active = true;
    }

    this.checkAvailability = function () {
        return this.active;
    }

    this.checkStatus = function () {
        return this.ready;
    }

}



function ProcessorClass() {
    let actualProcess = szallitas;

    var formData = {
        name: "",
        email: "",
        zipcode: "",
        city: "",
        street: "",
        contactName: "",
        contactPhone: "",
        message: ""
    };

    var setActualProcess = function (process) {
        if (process.checkAvailability()) {
            actualProcess = process;
            renderScreen();
        }
        else {
            console.log('declined');
        }
    }

    this.goTo = function (process) {
        setActualProcess(process);
    }

    this.saveForm = function () {
        formData.name = $("input#Nev").val();
        formData.email = $("input#Emailcim").val();
        formData.zipcode = $("input#Iranyitoszam").val();
        formData.city = $("input#Telepules").val();
        formData.street = $("input#Utca").val();
        formData.contactName = $("input#Atvevoneve").val();
        formData.contactPhone = $("input#Atvevotelefon").val();
        formData.message = $("input#Uzenet").val();

        console.log(formData);
    }

    var renderScreen = function () {

        $('.orderBody').removeClass('active');
        $('.orderBody.' + actualProcess.name).addClass('active');

        $('.processBar li').removeClass('active');
        $('.processBar li.' + actualProcess.name).addClass('active');

        var markToReady = function (processName) {
            $('.processBar li.' + processName).addClass('ready');
        }

        if (szallitas.checkStatus()) {
            markToReady(szallitas.name);
        }

        if (osszesito.checkStatus()) {
            markToReady(osszesito.name);
        }

        window.scrollTo(0, 0);


        return true;
    }


    $('button[data-formButton="szallitas"]').click(function () {
        var validator = Validator.get('szallitas');
        if (validator[0]) {
            szallitas.setReady()
            osszesito.setActive();
            Processor.saveForm();
            Processor.goTo(osszesito);
        } else {
            console.log(validator[1]);
        }
    });

    $('button[data-formButton="osszesito"]').click(function () {
        var validator = Validator.get('osszesito');
        if (validator[0]) {
            osszesito.setReady()
            fizetes.setActive();
            Processor.goTo(fizetes);
        } else {
            console.log(validator[1]);
        }
    });



    renderScreen();

}


var Validator = new ValidatorClass();


var szallitas = new Process({ name: 'szallitas' });
var osszesito = new Process({ name: 'osszesito' });
var fizetes = new Process({ name: 'fizetes' });

szallitas.setActive();


var Processor = new ProcessorClass();

var ErrorMessage = null;

var inputRules = [
    {
        input: $('#Nev'),
        rules: [
            {
                rule: function (value) {
                    if (value.search(" ") >= 0) {
                        return true
                    }
                    ErrorMessage = "Kérem a teljes nevét adja meg.";
                    return false
                }
            },
            {
                rule: function (value) {
                    if (value.length > 1) {
                        return true
                    }
                    ErrorMessage = "Kérem adja meg a nevét.";
                    return false
                }
            }
        ]
    },
    {
        input: $('#Emailcim'),
        rules: [
            {
                rule: function (value) {
                    if (value.search("@") >= 0) {
                        return true
                    }
                    ErrorMessage = "Kérem adja meg az e-mail címet.";
                    return false
                }
            },
            {
                rule: function (value) {
                    if (value.length >= 8) {
                        return true
                    }
                    ErrorMessage = "Az e-mail cím túl rövid.";
                    return false
                }
            },
            {
                rule: function (value) {
                    value = value.slice(value.indexOf("@"), value.length);
                    if (value.indexOf(".") > 0) {
                        return true
                    }
                    ErrorMessage = "Az e-mail címből kimaradt a pont.";
                    return false
                }
            },
            {
                rule: function (value) {
                    if (value.search(" ") < 0) {
                        return true
                    }
                    ErrorMessage = "Az e-mail címbe szóköz került.";
                    return false
                }
            }
        ]
    },
    {
        input: $('#Iranyitoszam'),
        rules: [
            {
                rule: function (value) {
                    if (!isNaN(value)) {
                        return true
                    }
                    ErrorMessage = "Csak szám értéket adhat meg.";
                    return false
                }
            },
            {
                rule: function (value) {
                    if (value.length === 4) {
                        return true
                    }
                    ErrorMessage = "4 jegyű irányítószámot fogadunk el. ";
                    return false
                }
            },
        ]
    },
    {
        input: $('#Telepules'),
        rules: [
            {
                rule: function (value) {
                    if (value.length > 2) {
                        return true
                    }
                    ErrorMessage = "Melyik településre szállíthatjuk?";
                    return false
                }
            },
        ]
    },
    {
        input: $('#Utca'),
        rules: [
            {
                rule: function (value) {
                    if (value.length > 4) {
                        return true
                    }
                    ErrorMessage = "Hrsz.-t is elfogadunk, de szükséges a pontos cím.";
                    return false
                }
            },
        ]
    },
    {
        input: $('#Atvevoneve'),
        rules: [
            {
                rule: function (value) {
                    if (value.search(" ") >= 0) {
                        return true
                    }
                    ErrorMessage = "Kérem a teljes nevét adja meg.";
                    return false
                }
            },
            {
                rule: function (value) {
                    if (value.length > 1) {
                        return true
                    }
                    ErrorMessage = "Kérem adja meg az átvevő nevét.";
                    return false
                }
            }
        ]
    },
    {
        input: $('#Atvevotelefon'),
        rules: [
            {
                rule: function (value) {
                    if (value.length > 1) {
                        return true
                    }
                    ErrorMessage = "Kérem adjon meg egy értesítendő telefonszámot.";
                    return false
                }
            }
        ]
    }
];


var validateInput = function (input) {

    ErrorMessage = null;

    let inputValue = input.val();
    let indexOfInput = null;
    let inputId = $(input).attr('id');

    for (let i = 0; i < inputRules.length; i++) {
        if (inputRules[i].input.is(input)) {
            indexOfInput = i;
        }
    }

    for (var i = 0; i < inputRules[indexOfInput].rules.length; i++) {
        inputRules[indexOfInput].rules[i].rule(inputValue);

    }

    if (ErrorMessage) {

        $('p[data-message="' + inputId + '"]').html(ErrorMessage);

        $(input).removeClass("is-success");
        $(input).addClass("is-danger");

    } else {
        $(input).removeClass("is-danger");
        $(input).addClass("is-success");
        $('p[data-message="' + inputId + '"]').html("");
    }

}

var messageList = [];


var validateForm = function () {
    var hasError = false;

    for (var i = 0; i < inputRules.length; i++) {

        var input = inputRules[i].input;


        validateInput(input);

        if (ErrorMessage) {
            messageList.push(ErrorMessage);
            hasError = true;
        }

    }

    return hasError;

};
