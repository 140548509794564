
(function () {
    "use strict";

    var burger = document.querySelector('.burger');
    var menu = document.querySelector('#' + burger.dataset.target);
    burger.addEventListener('click', function () {
        burger.classList.toggle('is-active');
        menu.classList.toggle('is-active');
    });

    $('.tabContent.fem').hide();

    $('.setBeton').click(function () {
        $('.tabContent.fem').hide();
        $('.tabContent.beton').show();
        $('li.setBeton').addClass('is-active');
        $('li.setFem').removeClass('is-active');
    });
    $('.setFem').click(function () {
        $('.tabContent.beton').hide();
        $('.tabContent.fem').show();
        $('li.setFem').addClass('is-active');
        $('li.setBeton').removeClass('is-active');
    });



    function isInViewport($this) {
        var item = $this;

        var elementTop = item.offset().top;
        var image = $("#application .fixedToTop")

        var viewportTop = image.offset().top + image.outerHeight();

        if (elementTop < viewportTop == true) {
            item.addClass('active');
        } else {
            item.removeClass('active');
        }
    }

    $(window).scroll(function () {
        $("#application .media.method").each(function () {
            isInViewport($(this));
        });

    });

})();