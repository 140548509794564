/* eslint-disable no-unused-vars */


const productDataByJson = {
    id: 1,
    name: "Product name",
    price: 23000,
    transitPrice: 3000,
    optionGroups: [

        {
            groupName: "Gyorsító adalék",
            id: 1,
            options: [
                {
                    name: "Gyorsító adalék nélkül",
                    id: 1,
                    price: 0,
                    default: true,
                    selected: true
                },
                {
                    name: "SPE gyorsító adalék",
                    id: 2,
                    price: 8000,
                    default: false,
                    selected: false
                },
                {
                    name: "AB-21 gyorsító adalék",
                    id: 3,
                    price: 12000,
                    default: false,
                    selected: false
                }
            ]
        },

        {
            groupName: "Választható kiegészítők",
            id: 2,
            options: [
                {
                    name: "Adalék anyag nélkül",
                    id: 1,
                    price: 0,
                    default: true,
                    selected: true
                },
                {
                    name: "Adalég anyag",
                    id: 2,
                    price: 12000,
                    default: false,
                    selected: false
                }
            ]
        }
    ]
};

class FormController {

    constructor(productData) {
        this.product = productData;
        this.optionGroupContainer = [];
        this.quantityController = new QuantityControllerClass();

    }

    refreshSummary() {
        let price = this.getPrice();

        $('#gross-sum-price').text(formatNumbers(price.gross) + " Ft");
        $('#net-sum-price').text(formatNumbers(price.net) + " Ft");
        $('#tax-sum-price').text(formatNumbers(price.tax) + " Ft");
    }

    buildOptionGroupContainer() {
        this.product.optionGroups.forEach((optionGroup) => {
            let groupId = optionGroup.id;
            this.optionGroupContainer.push(new ConcreteOptionGroupController(groupId));
        });
    }

    getPrice() {
        let kerekites = (price) => Math.floor((price + 4.9999) / 5) * 5
        let x = this.product.price;

        let price = {
            gross: 0,
            net: 0,
            tax: 0
        }

        this.product.optionGroups.forEach((group) => {
            x += getSelectedOption(group.id)[0].price;
        });

        x = x * this.quantity + this.product.transitPrice;

        price.gross = kerekites(x);
        price.tax = kerekites(x * 0.27);
        price.net = kerekites(x - price.tax);

        return price;
    }

    renderView() {
        this.buildOptionGroupContainer();

        this.optionGroupContainer.forEach((optionGroup) => {
            optionGroup.buildOptions();
            optionGroup.insert();
        });
    }
}



class QuantityControllerClass {

    constructor() {
        this.quantity = 1;

        $(".quantity-button.subtract").click(() => {
            this.decrease();
            Form.refreshSummary()
            $("#quantity").val(this.quantity);
        });

        $(".quantity-button.add").click(() => {
            this.increase();
            Form.refreshSummary()
            $("#quantity").val(this.quantity);
        });

    }

    increase() {
        this.quantity = this.quantity + 1;
    }

    decrease() {
        (this.quantity >= 2) ? this.quantity = this.quantity - 1 : null
    }
}


class OptionGroupController {

    constructor(groupId) {
        this.optionGroupId = groupId;
        this._options = [];
        this._optionGroup = getGroupById(groupId);
        this.selectedOptionId = null;

    }


    getGroupById(id) {
        let optionGroups = this.product.optionGroups;
        let group = undefined;

        optionGroups.forEach((x) =>
            (x.id === id) ? group = x : null);

        return group;
    }

    static getSelectedOption() {
        console.log(this);
        return this._options.filter((option) => option.selected);
    }

    groupView() {
        let groupDom = $('<div class="form-group my-5 additional-group" />');
        groupDom.append($('<h2 class="is-size-6" />').text(this.optionGroup.groupName));
        groupDom.append($('<a href="#" />').text('Mikor van szükségem gyorsító adalékra?'));

        groupDom.groupId = this.optionGroup.id;
        groupDom.append(this.getOptionsView());

        return groupDom;
    }

    get optionGroup() {
        return this._optionGroup;
    }

    insert() {
        $('#optionGroups').append(this.groupView());
    }

    getOptionsView() {

        let buttonDomStack = [];

        this._options.forEach((option) => {

            let optionDom = option.buttonView();
            buttonDomStack.push(optionDom);

        });

        return buttonDomStack;
    }

    buildOptions(force = false) {

        this._optionGroup.options.forEach((option) => {
            let arg = {
                name: option.name,
                price: option.price,
                id: option.id,
                default: option.default,
                groupId: this.optionGroupId
            }
            let object = this.createOptionController(arg);
            this._options.push(object);

            //console.log(object)

        });


    }

    click() {
        $(this).addClass('selected');
        OptionGroupController.getSelectedOption();
    }
}

class ConcreteOptionGroupController extends OptionGroupController {
    constructor(...arg) {
        super(...arg);
    }

    createOptionController(...arg) {
        return new OptionController(...arg);
    }
}



class OptionController extends OptionGroupController {

    constructor(arg) {
        super(arg);
        this.name = arg.name;
        this.price = arg.price;
        this.selected;
        this.id = arg.id;
        this.defaultBoolean = arg.default;
        this.groupId = arg.groupId;
        this._button;
        this.selectDefaultOptions();
    }

    selectDefaultOptions() {
        if (this.defaultBoolean)
            this.selected = true;
    }

    buttonView() {

        let defaultSelection = () => (this.defaultBoolean) ? 'selected' : '';

        this._button = $('<div />');
        this._button.addClass('option my-2 ' + defaultSelection())
            .append($('<span />').text(this.name))
            .append(
                $('<span class="option-price" />')
                    .text((this.price) ? '+ ' + this.price : '')
            );

        let meta = {
            optionId: this.id,
            selected: this.defaultBoolean
        }

        $(this._button).data('object', this);
        this._button.click(super.click);

        return this._button;

    }


}


let Form = new FormController(productDataByJson);

Form.renderView();


function getGroupById(id) {
    let optionGroups = Form.product.optionGroups;

    let group = undefined;

    optionGroups.forEach((x) =>
        (x.id === id) ? group = x : null);

    return group;
}

let groupOptions = (groupId) => getGroupById(groupId).options;


let getSelectedOption = (groupId) =>
    groupOptions(groupId).filter((option) => option.selected);

function selectOption(groupId, optionId) {
    groupOptions(groupId).forEach((option) => {
        if (optionId == option.id)
            option.selected = true;
        else
            option.selected = false;

        $('.additional-group[additional-data="' + groupId + '"] .selected').removeClass('selected');
        $('.additional-group[additional-data="' + groupId + '"] .option[additional-option-data="' + optionId + '"]').addClass('selected');
    });
}




/*
function InitView() {
    Form.product.optionGroups.forEach((group) => {
        let groupId = group.id;

        let optionId = () =>
            groupOptions(groupId).filter((option) => option.default)[0].id;

        $('.additional-group[additional-data="' + groupId + '"] .option[additional-option-data="' + optionId() + '"]').addClass('selected');

    });
}
*/

//InitView();
//optionGroupController.insert()


function formatNumbers(nStr) {
    nStr += '';
    let x = nStr.split('.');
    let x1 = x[0];
    let x2 = x.length > 1 ? '.' + x[1] : '';
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, '$1' + ' ' + '$2');
    }
    return x1 + x2;
}

/*
$(".additional-group .option").click((event) => {
    let button = event.currentTarget;

    let groupId = parseInt($(button).parent().attr("additional-data"));
    let optionId = parseInt($(button).attr("additional-option-data"));

    if (Number.isInteger(groupId) && Number.isInteger(optionId)) {
        selectOption(groupId, optionId);
    } else {
        console.log('Beviteli hiba');
    }

    Form.refreshSummary();

});

*/

